import { useEffect, useState } from "react"
import { fetchGetSubFolders } from "../FolderApi"
import { toast } from "react-toastify"
import Folder from "./Folder"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../services/i18n/i18nSlice"

export default function SubFolders ({parentFolder}) {

    const t = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const hasContent = ( parentFolder?.files?.length > 0 || parentFolder?.video?.length > 0 || parentFolder?.links?.length > 0 ) && parentFolder.parent_folder_id !== null
    const [subFolders, setSubFolders] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const loadSubFolders = async () => {
        setIsLoading(true)
        const response = await fetchGetSubFolders({parent_folder_id: parentFolder?.folder_id})
        if (response.success){
            setSubFolders(response.data.folders)
            setIsLoading(false)
        } else {
            toast.error(t.training.error_loading_folder)
        }
    } 

    const subfoldersAreTraining = () => {
        return subFolders.find((f) => ((!f.videos || f.videos?.length === 0) && (!f.links || f.links?.length === 0) && (!f.files || f.files?.length === 0) && f.training?.ready) )
    }

    useEffect(( )=>{
        setSubFolders([])
        loadSubFolders()
    },[parentFolder.folder_id])

    return(
        <>
            <div className={`folders_list ${subFolders?.length > 0 ? 'mt-4' : ''} ${hasContent || subfoldersAreTraining() ? 'rounded' : '' }`}>
                {subFolders?.map((folder, folder_id) => {
                    return(
                        <Folder folder={folder} hasContent={hasContent || subfoldersAreTraining()} key={folder_id}/>
                    )
                })}
                {isLoading && Array.from(Array(3), (v,i)=> <Folder key={i}/>)}
            </div>
            {subFolders?.length > 0 &&
                <hr style={{background:'#ECECEC', height:'4px', border:'0', borderRadius:'10px'}} />
            }
        </>
    )
}